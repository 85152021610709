import 'reflect-metadata';
import Vue from 'vue';
import Vuex from 'vuex';
import App from './views/App/App.vue';
import router from './router';
import BootstrapVue from 'bootstrap-vue';
import '@/assets/css/theme.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import smartlookClient from 'smartlook-client';
import VueObserveVisibility from 'vue-observe-visibility';
import 'vue-select/dist/vue-select.css';
import { getStoreInstance } from './store';
import './router/routerHooks';

// plugins
import '@/plugins/chartjs';
import '@/plugins/fontawesome';
import '@/plugins/vueTippy';
import '@/plugins/veeValidate';

console.log(`Running ${process.env.APP_RELEASE}`);

window.captureException = (error, options) => {
    console.error(error);
    window.DD_RUM && window.DD_RUM.addError && window.DD_RUM.addError(error, options);
};

if (process.env.NODE_ENV !== 'development') {
    window.rumStartRecording = () => {
        window.DD_RUM && window.DD_RUM.startSessionReplayRecording();
    };

    window.rumStopRecording = () => {
        window.DD_RUM && window.DD_RUM.stopSessionReplayRecording();
    };

    smartlookClient.init('2a813d14bebc8278a74106adec26d2ae6c5ae256');
}

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(Vuex);
Vue.use(VueObserveVisibility);

const flowId = new URL(location.href).searchParams.get('flowId');
(async () => {
    new Vue({
        router,
        store: await getStoreInstance(flowId),
        render: (h) => h(App)
    }).$mount('#app');
})();
